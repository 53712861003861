import React from 'react'
import NetlifyForm from 'react-netlify-form'
import Recaptcha from 'react-recaptcha'

const Contact = (props) => (
    <section id="contact">
        <div className="inner">

            <section>
                <NetlifyForm
                    name='Contact Form'
                    recaptcha={{
                        sitekey: '6LcRpYwUAAAAAPw0J3tZUF-2HwjX4mtLyLazU6xo',
                        size: 'normal'
                    }}
                >
                    {({ loading, error, recaptchaError, success, recaptcha }) => (
                        <div>
                            {loading &&
                                <div>Sending...</div>
                            }
                            {error &&
                                <div>Sorry, your information was not sent. Please try again later.</div>
                            }
                            {recaptchaError &&
                                <div>Recaptcha did not match. Please make sure the box is checked.</div>
                            }
                            {success &&
                                <div>Thank you for contacting us, we will get back to you shortly.</div>
                            }
                            {!loading && !success &&
                                <div>
                                    <div className="field">
                                        <label htmlFor="name">Name</label>
                                        <input type="text" name="name" id="name" required={true} />
                                    </div>
                                    <div className="field half first">
                                        <label htmlFor="email">Email</label>
                                        <input type="email" name="email" id="email" required={true} />
                                    </div>
                                    <div className="field half">
                                        <label htmlFor="phone">Phone</label>
                                        <input type="tel" name="phone" id="phone" required={true} />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="message">Message</label>
                                        <textarea name="message" id="message" rows="6" required={true}></textarea>
                                    </div>
                                    <div className="field">
                                        {recaptcha}
                                    </div>
                                    <ul className="actions">
                                        <li><input type="submit" value="Send Message" className="special" /></li>
                                        <li><input type="reset" value="Clear" /></li>
                                    </ul>
                                </div>
                            }
                        </div>
                    )}
                </NetlifyForm>

            </section>
            <section className="split">
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-envelope"></span>
                        <h3>Email</h3>
                        <a href="mailto:info@littlebirds.co.nz">info@littlebirds.co.nz</a>
                    </div>
                </section>
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-phone"></span>
                        <h3>Phone</h3>
                        <a href="tel:+64225002832">022 500 2832</a>
                    </div>
                </section>
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-facebook"></span>
                        <h3><a target="_" href="https://www.facebook.com/littlebirdsspeechlanguagetherapy">Facebook</a></h3>
                        <a target="_" href="https://www.facebook.com/littlebirdsspeechlanguagetherapy" className="icon alt fa-facebook"><span className="label">Facebook</span></a>
                    </div>
                </section>

            </section>
        </div>
    </section>
)

export default Contact
