import React from 'react'
import Link from 'gatsby-link'
import PropTypes from 'prop-types'
// <Link to="/" className="logo"><span>Littlebirds</span></Link>



// const Header = (props) => (
//     <header id="header" className="alt">
//     <Link to="/" className="logo"><img src={logo} /></Link>
//         <nav>
//             <a className="menu-link" onClick={props.onToggleMenu} href="javascript:;">Menu</a>
//         </nav>
//     </header>
// )

const Header = (props) => (
    <header id="header" className="alt">
        <Link to="/" className="logo"><span>Littlebirds</span></Link>
        <nav className="wide">
            <div className="wide-inner">
                <ul className="links">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/about">About</Link></li>
                    <li><Link to="/about#location">Location</Link></li>
                    <li><Link to="/services">Services</Link></li>
                    <li><Link to="/services#fees">Fees</Link></li>
                </ul>
            </div>
        </nav>
        <nav className="narrow">
            <a className="menu-link" onClick={props.onToggleMenu} href="javascript:;">Menu</a>
        </nav>
    </header>
)

Header.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Header
