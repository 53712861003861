import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import logoBird from '../assets/images/logoBird.jpg';

const siteUrl = "https://littlebirds.co.nz"
const schemaOrgJSONLD = {
    "@context": "http://schema.org",
    "@type": "Organization",
    "url": siteUrl,
    "logo": `${siteUrl}${logoBird}`,
    "contactPoint": [
        {
            "@type": "ContactPoint",
            "telephone": "+64 22 500 2832",
            "contactType": "customer service"
        }
    ],
    "sameAs": [
        "https://www.facebook.com/littlebirdsspeechlanguagetherapy"
    ]
}
const siteDescription = "Littlebirds Speech, Language and Literacy offer speech and language assessment and intervention for children in the wider Porirua area and surrounding suburbs. We are highly experienced and passionate about building your child's communication skills."


const PageHelmet = (props) => (
    <Helmet>
        <title>{props.title} - Littlebirds Speech, Language and Literacy</title>
        <meta name="description" content={siteDescription} />
        <meta chatSet="utf-8" />
        <link rel="canonical" href={siteUrl} />
        <script type="application/ld+json">
            {JSON.stringify(schemaOrgJSONLD)}
        </script>
    </Helmet>
);

PageHelmet.propTypes = {
    title: PropTypes.string
}

export default PageHelmet;